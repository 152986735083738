import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getCarAdsHttp=async(data, token)=>{
    var res=await httpRequestGet('/cars/', data, token)
    return(res)
}
export const getCarSelectionHttp=async(token)=>{
    var res=await httpRequestGet('/car-selections/', {}, token)
    return(res)
}
export const getCarAdsOneHttp=async(id, token)=>{
    var res=await httpRequestGet('/cars/'+id+"/detail/", {}, token)
    return(res)
}
export const setCarAdsHttp=async(data, token)=>{
    var res=await httpRequestPost('/cars/', data, token)
    return(res)
}
export const editCarAdsHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/cars/${id}/update/`, data, token)
    return(res)
}
export const editTopCarAdsHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/top-ads/cars/${id}/`, data, token)
    return(res)
}

export const deleteCarAdsHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/cars/${data}/delete/`, token)
    return(res)
}