import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import { getHouseServiceAdsOneHttp } from '../host/requests/HouseServiceAds'
import { useParams } from 'react-router-dom'
import { formatNumber } from '../components/Alert'
import { Col, Row } from 'antd'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'
import { FaFacebookF, FaGlobe, FaInstagram, FaPhone, FaTelegramPlane } from 'react-icons/fa'
import { formatFacebook, formatInstagram, formatTelegram, formatwebsite } from '../components/Checkers'

export default function HouseServiceAdsOne() {
    const {id}=useParams()
    const [data, setdata]=useState(null)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)
    useEffect(()=>{
        getData()
    },[])
    const getData=async()=>{
           setloader(true)
      
        try{
            var res=await getHouseServiceAdsOneHttp(id, token_access)
            setdata(res.data)
            setloader(false)
        }catch (error){
            console.log(error)
            setloader(false)
        }
    }
  return (
    data!=null?<>
    
     <div className='contentHeader'>
   
     </div>
    <div className='content'>
    <div className='contentBody'>
        <Row>
            <Col lg={12} md={24}>
            <div className='product_text'>
         <div className='product_text_head'>
         <div className='head_text_title'>
            <h1>{data.name}</h1>
         </div>
           
         </div>
            
            <div className='product_box'>
            {data.region!=null || data.district!=null?<div className='product_item'>
            <b>Manzil :</b><span> {data.region!=null?data.region[`name_uz`]:''}, {data.district!=null?data.district[`name_uz`]:''}</span>
            </div>:<></>}
            {data.fullname!=null?<div className='product_item'>
            <b>F.I.Sh :</b><span> {data.fullname}</span>
            </div>:<></>}
            {data.gender!=null?<div className='product_item'>
            <b>Jins :</b><span> {data.gender==='1'?"Erkak":"Ayol"}</span>
            </div>:<></>}
            {data.payment_type!=null?<div className='product_item'>
            <b>To'lov turi :</b><span> {data.payment_type[`name_uz`]}</span>
            </div>:<></>}
            {data.experience!=null?<div className='product_item'>
            <b>Staj :</b><span> {data.experience} yil</span>
            </div>:<></>}
            {data.service_types!=null && data.service_types.length!==0?<div className='product_item'>
            <b>Xizmat turlari :</b><span> {data.service_types.map((item, key)=>{
                var str=""
                if(key!==0){
                    str=", "
                }
                str+=item[`name_uz`]
                return(str)
            })}</span>
            </div>:<></>}
            <p className='product_price'>{formatNumber(data.price_usd)+" $"} | {formatNumber(data.price_uzs)+" so'm"}</p>
            {data.additional!==null && data.additional.length!==0?<div style={{width:'100%', padding:'0'}} className='product_comment'>
               <p dangerouslySetInnerHTML={{__html:data.additional}}/> 
            </div>:''}
            </div>
            
         </div>
         <div className='add_contact_box'>
         {data.created_by!==null?<div className='user_box'>
                <div className='avatar' style={{backgroundImage:`url(${data.created_by!==null && data.created_by.image!==null?data.created_by.image:null})`}}  />
                <div className='user_text'>
                  <h1>{data.created_by.first_name}</h1>
                  {data.phone!==null && data.phone.length!==0?<>{
                 <a href={`tel: ${data.phone}`}>{data.phone}</a>}
                  </>:<></>}
                </div>
                
            </div>:<></>}
            <div className='add_contact_links'>
            {data.phone!==null && data.phone.length!==0?<a href={`tel: ${data.phone}`} className='add_contact_icon' ><FaPhone /></a>:<></>}
            {data.telegram!==null && data.telegram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatTelegram(data.telegram)}><FaTelegramPlane /></a>:<></>}
            {data.instagram!==null && data.instagram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatInstagram(data.instagram)}><FaInstagram /></a>:<></>}
            {data.facebook!==null && data.facebook.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatFacebook(data.facebook)}><FaFacebookF /></a>:<></>}
            {data.website!==null && data.website.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatwebsite(data.website)}><FaGlobe /></a>:<></>}
            
            
            </div>
            </div>
         
         </Col>
            <Col lg={12} md={24}>
            <div className='images_ads'>
                {data.images.map((item, key)=>{
                    return(<div className='images_ads_box'><img src={item.image} /></div>)
                })}
            </div>
            
 
            </Col>
        </Row>
   
    </div> </div>
    </>:<></>
  )
}
