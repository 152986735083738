import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getHouseAdsHttp=async(data, token)=>{
    var res=await httpRequestGet('/houses/', data, token)
    return(res)
}
export const getHouseSelectionHttp=async(token)=>{
    var res=await httpRequestGet('/house-selections/', {}, token)
    return(res)
}
export const getHouseAdsOneHttp=async(id, token)=>{
    var res=await httpRequestGet('/houses/'+id+"/detail/", {}, token)
    return(res)
}
export const setHouseAdsHttp=async(data, token)=>{
    var res=await httpRequestPost('/houses/', data, token)
    return(res)
}
export const editHouseAdsHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/houses/${id}/update/`, data, token)
    return(res)
}
export const editTopHouseAdsHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/top-ads/houses/${id}/`, data, token)
    return(res)
}

export const deleteHouseAdsHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/houses/${data}/delete/`, token)
    return(res)
}