import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getTarifsHttp=async(data, token)=>{
    var res=await httpRequestGet('/billing/admin/tarifs/', data, token)
    return(res)
}
export const setTarifsHttp=async(data, token)=>{
    var res=await httpRequestPost('/billing/admin/tarifs/', data, token)
    return(res)
}
export const editTarifsHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/billing/admin/tarifs/${id}/update/`, data, token)
    return(res)
}
export const deleteTarifsHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/billing/admin/tarifs/${data}/delete/`, token)
    return(res)
}