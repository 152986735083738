import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import { getHouseAdsOneHttp } from '../host/requests/HouseAds'
import { useParams } from 'react-router-dom'
import { formatNumber } from '../components/Alert'
import { Col, Row } from 'antd'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'
import { FaFacebookF, FaGlobe, FaInstagram, FaPhone, FaTelegramPlane } from 'react-icons/fa'
import { formatFacebook, formatInstagram, formatTelegram, formatwebsite } from '../components/Checkers'

export default function HouseAdsOne() {
    const {id}=useParams()
    const [data, setdata]=useState(null)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)
    useEffect(()=>{
        getData()
    },[])
    const getData=async()=>{
           setloader(true)
      
        try{
            var res=await getHouseAdsOneHttp(id, token_access)
            setdata(res.data)
            setloader(false)
        }catch (error){
            console.log(error)
            setloader(false)
        }
    }
  return (
    data!=null?<>
    
     <div className='contentHeader'>
   
     </div>
    <div className='content'>
    <div className='contentBody'>
        <Row>
            <Col lg={12} md={24}>
            <div className='product_text'>
           <div className='product_text_head'>
                <div className='head_text_title'>
            <h1>{data.name}</h1>
            <p>{data.house_type[`name_uz`]}</p>
            </div>
            </div>
            <div className='product_box'>
            {data.region!=null || data.district!=null?<div className='product_item'>
            <b>Manzil :</b><span> {data.region!=null?data.region[`name_uz`]:''}, {data.district!=null?data.district[`name_uz`]:''}</span>
            </div>:<></>}
            {data.b_type!=null?<div className='product_item'>
            <b>Bozor turi :</b><span> {data.b_type[`name_uz`]}</span>
            </div>:<></>}
            {data.makler!=null?<div className='product_item'>
            <b>Vositachi haqqi :</b><span> {data.makler?"Bor":"Yo'q"}</span>
            </div>:<></>}
            {data.surface!=null?<div className='product_item'>
            <b>Maydoni (m²) :</b><span> {data.surface}</span>
            </div>:<></>}
            {data.rooms!=null?<div className='product_item'>
            <b>Xonalar soni :</b><span> {data.rooms}</span>
            </div>:<></>}
            {data.floor!=null?<div className='product_item'>
            <b>Qavat raqami :</b><span> {data.floor}</span>
            </div>:<></>}
            {data.floors!=null?<div className='product_item'>
            <b>Umumiy qavatlar soni :</b><span> {data.floors}</span>
            </div>:<></>}
            {data.year!=null?<div className='product_item'>
            <b>Qurilgan yili :</b><span> {data.year}</span>
            </div>:<></>}
            {data.mat_type!=null?<div className='product_item'>
            <b>Qurulish turi :</b><span> {data.mat_type[`name_uz`]}</span>
            </div>:<></>}
            {data.repair!=null?<div className='product_item'>
            <b>Tamiri :</b><span> {data.repair[`name_uz`]}</span>
            </div>:<></>}
            {data.sanuzel!=null?<div className='product_item'>
            <b>Sanuzel :</b><span> {data.sanuzel[`name_uz`]}</span>
            </div>:<></>}
            {data.furnished!=null?<div className='product_item'>
            <b>Uy jihozlari :</b><span> {data.furnished?"Bor":"Yo'q"}</span>
            </div>:<></>}
            {data.items_in!=null && data.items_in.length!==0?<div className='product_item'>
            <b>Uyda bor :</b><span> {data.items_in.map((item, key)=>{
                var str=""
                if(key!==0){
                    str=", "
                }
                str+=item[`name_uz`]
                return(str)
            })}</span>
            </div>:<></>}
            {data.near!=null && data.near.length!==0?<div className='product_item'>
            <b>Yaqin atrofda :</b><span> {data.near.map((item, key)=>{
                var str=""
                if(key!==0){
                    str=", "
                }
                str+=item[`name_uz`]
                return(str)
            })}</span>
            </div>
            :<></>}
          <p className='product_price'>{formatNumber(data.price_usd)+" $"} | {formatNumber(data.price_uzs)+" so'm"}</p>
            
          
            </div>
            {data.additional!==null && data.additional.length!==0?<div className='product_comment'>
               <p dangerouslySetInnerHTML={{__html:data.additional}}/> 
            </div>:''}
         </div>
         <div className='add_contact_box'>
         {data.created_by!==null?<div className='user_box'>
                <div className='avatar' style={{backgroundImage:`url(${data.created_by!==null && data.created_by.image!==null?data.created_by.image:null})`}}  />
                <div className='user_text'>
                  <h1>{data.created_by.first_name}</h1>
                  {data.phone!==null && data.phone.length!==0?<>{
                 <a href={`tel: ${data.phone}`}>{data.phone}</a>}
                  </>:<></>}
                </div>
                
            </div>:<></>}
            <div className='add_contact_links'>
            {data.phone!==null && data.phone.length!==0?<a href={`tel: ${data.phone}`} className='add_contact_icon' ><FaPhone /></a>:<></>}
            {data.telegram!==null && data.telegram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatTelegram(data.telegram)}><FaTelegramPlane /></a>:<></>}
            {data.instagram!==null && data.instagram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatInstagram(data.instagram)}><FaInstagram /></a>:<></>}
            {data.facebook!==null && data.facebook.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatFacebook(data.facebook)}><FaFacebookF /></a>:<></>}
            {data.website!==null && data.website.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatwebsite(data.website)}><FaGlobe /></a>:<></>}
            
            
            </div>
            </div>
         
         </Col>
            <Col lg={12} md={24}>
            <div className='images_ads'>
                {data.images.map((item, key)=>{
                    return(<div className='images_ads_box'><img src={item.image} /></div>)
                })}
                {data.video!=null?<div className='images_ads_box'>
                  <video style={{width:'100%'}} controls src={data.video} />
                </div>:<></>}
                
            </div>
            
           <div className='yandex_box'>
           
        <YMaps>
     <Map defaultState={{
       center: [data.latitude!==null?Number(data.latitude):0,data.longitude!==null?Number(data.longitude):0],
       zoom: 14,
     }} width="100%" height="100%" options={{
       minZoom: 5,
       maxZoom: 20,
     }}>
       <Placemark geometry={[data.latitude!==null?Number(data.latitude):0,data.longitude!==null?Number(data.longitude):0]} properties={{ hintContent: 'My Placemark', balloonContent: 'This is my placemark!' }} />
       <ZoomControl options={{ float: 'left' }} />
     </Map>
  
   </YMaps>
  
   </div>
 
            </Col>
        </Row>
   
    </div> </div>
    </>:<></>
  )
}
