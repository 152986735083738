import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { deleteHouseServiceAdsHttp, editHouseServiceAdsHttp, getHouseServiceAdsHttp, getHouseServiceSelectionHttp, setHouseServiceAdsHttp } from '../host/requests/HouseServiceAds'
import { useStore } from '../store/Store'
import { Button, Col, Form, Input, Modal, Row, Table } from 'antd'
import { dangerAlert, formatNumber, successAlert } from '../components/Alert'
import { BsPencilFill, BsTrashFill } from 'react-icons/bs'
import { IoIosWarning } from "react-icons/io";
import Pagination from '../components/Pagination'
import { Link } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import { httpRequestGet } from '../host/Host'
export default function HouseServiceAds() {
    const [data, setData]=useState([])
    const [open, setOpen]=useState(false)
    const [modal, contextHolder] = Modal.useModal();
    const [error, setError]=useState(false)
    const [req, setreq]=useState(false)
    const [b_type_data, setb_type_data]=useState([])
    const [region_data, setregion_data]=useState([])
    const [sethouse_type_data, setsethouse_type_data]=useState([])
    const [setitems_in_data, setsetitems_in_data]=useState([])
    const [setmat_type_data, setsetmat_type_data]=useState([])
    const [setnear_data, setsetnear_data]=useState([])
    const [setrepair_data, setsetrepair_data]=useState([])
    const [setsanuzel_data, setsetsanuzel_data]=useState([])
    const [sethouse_product_type_data, setsethouse_product_type_data]=useState([])
    const [edit, setedit]=useState(null)
    const [image_str, setimage_str]=useState(null)
    const [image, setimage]=useState(null)
    const [search, setsearch]=useState(null)
    const [page, setpage]=useState(1)
    const [count, setcount]=useState(0)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)

    const [form] = Form.useForm()
    useEffect(()=>{
        getData()
    },[page, search])
    const getData=async()=>{
      if(search===null || search.length===0){
          setloader(true)
      }

      try{
          var res=await getHouseServiceAdsHttp({name__icontains:search, page:page}, token_access)
          setData(res.data.results)
          setcount(res.data.count)
          setloader(false)
      }catch (error){
          console.log(error)
          setloader(false)
      }
  }
  const getSelection=async()=>{
   
    try{
        var res=await getHouseServiceSelectionHttp(token_access)
        setb_type_data(res.data.b_type)
        sethouse_type_data(res.data.house_type)
        setitems_in_data(res.data.items_in)
        setmat_type_data(res.data.mat_type)
        setnear_data(res.data.near)
        setrepair_data(res.data.repair)
        setsanuzel_data(res.data.sanuzel)
        sethouse_product_type_data(res.data.product_type)
        var res1=await httpRequestGet('/regions/')
  setregion_data(res1.data)
    }catch (error){
        console.log(error)
        setloader(false)
    }
}
    const openModal=()=>{
        setOpen(true)
    }
    const closeModal=()=>{
        setOpen(false)
        form.resetFields()
        setedit(null)
        setimage(null)
        setError(false)
        setreq(false)
        
    }
   const openModalEdit=(item)=>{
           setedit(item.id)
           if(item.image==null){
            setreq(true)
           }
           form.setFieldsValue(item)
           openModal()
   }
    const openModalConfirm=(id)=>{
        console.log(id)
        modal.confirm({
            title: 'Eslatma !!!',
            icon: <span className='warning_icon'><IoIosWarning /></span>,
            content: "O'chirilgan ma'lumot qaytib tiklanmaydi. Ma'lumotni o'chirishga ishonchingiz komilmi?",
            okText: 'Ha',
            cancelText: "Yo'q",
            onOk:()=>{deleteData(id)},
           
          });
    }
    const deleteData=async(id)=>{
         setloader(true)
         try{
            var res=await deleteHouseServiceAdsHttp(id, token_access)
            successAlert("Ma'lumot o'chirildi")
            getData()
         }catch(error){
            dangerAlert("Ma'lumot o'chirilmadi")
            setloader(false)
         }
    }
    const handleImageChange=(e)=>{
      if(e.target.files.length>0){
        setimage(e.target.files[0])
      }else{
        setimage(null)
      }
    }
    const onFinish=async(val)=>{
      setloader(true)
      const data = new FormData();
      if(image!=null){
        data.append('image', image);
      }
      data.append('name_uz', val.name_uz);
      data.append('name_ru', val.name_ru);
        try{
            if(edit===null){
                var res=await setHouseServiceAdsHttp(data, token_access)
            }else{
                var res=await editHouseServiceAdsHttp(edit, data, token_access)
            }
            
            successAlert("Ma'lumot qo'shildi")
            setpage(1)
            closeModal()
            getData()
        }catch (error){
          setloader(false)
            dangerAlert("Ma'lumot qo'shilmadi")
        }
    }
    const checkHasOrNot=(val, lan)=>{
        if(edit===null){
            var a=data.filter(x=>x[`name_${lan}`].toLowerCase().replaceAll(" ", "")===val.toLowerCase().replaceAll(" ", ""))
            if(a.length!==0){
                setError(true)
                dangerAlert("Bunday qiziqish bazaga qo'shilgan")
            }else{
                setError(false)
            }
        }
    }
    const columns = [
        {
            title: '#',
            className:"mini_column",
            dataIndex: 'id',
            render:(data, row,index)=>{return(((page-1)*15)+(index+1))},
           },
           {
            title: 'Rasm',
            dataIndex: 'image',
            render:(dat)=>{return(<img style={{height:'70px'}} src={dat.image}/>)}
           },
           {
            title: 'Sarlavha',
            dataIndex: 'name',
            
           },
           {
            title: 'Tuman/Shahar',
            dataIndex: 'district',
            render:(item)=>(item!=null?item.name_uz:null)
           },
           {
            title: 'Narx',
            dataIndex: 'price_uzs',
            render:(item)=>(item!=null?formatNumber(item)+" so'm":null)
           },
           
           {
            title: 'Holat',
            dataIndex: 'status',
            render:(dat)=>{return(dat===1?<span className='success_text'>Aktiv</span>:<span className='error_text'>Aktiv emas</span>)}
           },
           {
            title: 'TOP',
            dataIndex: 'is_top',
            render:(dat)=>{return(dat?<span className='success_text'>Topda</span>:<span className='error_text'>Topda emas</span>)}
           },
           {
            title: "",
            className:"mini_column",
            dataIndex: 'id',
            render:(id)=>{return(<Button type="primary" onClick={()=>{openModalConfirm(id)}} danger shape="circle" icon={<BsTrashFill />} />)},
           },
           {
            title: "",
            className:"mini_column",
            dataIndex: 'id',
            render:(id)=>{return(<Link to={`/home-service-ads/${id}`}><Button type="primary"  shape="circle" icon={<FaEye />} /></Link>)},
           },
          //  {
          //   title: "",
          //   className:"mini_column",
          //   dataIndex: '',
          //   render:(id)=>{return(<Button onClick={()=>{openModalEdit(id)}} type="primary" className='warning_btn' shape="circle" icon={<BsPencilFill />} />)},
          //  }
       
      ];
  return (
    <>
     <div className='contentHeader'>
     <Header setpage={setpage} search={search} setsearch={setsearch}/>
     </div>
    <div className='content'>
    <div className='contentBody'>
     
    <Table pagination={false} rowKey={(data)=>{return(data.id)}} columns={columns} dataSource={data}/>
    {count>15?<Pagination count={count} page={page} setpage={setpage}/>:<></>}
    <Modal footer={false} title={`Ma'lumot ${edit===null?"qo'shish":"o'zgartirish"}`} open={open} onOk={closeModal} onCancel={closeModal}>
    <Form
    name="add"
    form={form}
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    
  >
    <Form.Item
      label="Nomi (uz)"
      name="name_uz"
      rules={[
       
        {
           required: true,
           message: "",
            
        }
      ]}
    >
      <Input onChange={(e)=>{checkHasOrNot(e.target.value, "uz")}} className='input' />
    </Form.Item>
   
    <Form.Item
      label="Nomi (ru)"
      name="name_ru"
      rules={[
       
        {
           required: true,
           message: "",
            
        }
      ]}
    >
      <Input onChange={(e)=>{checkHasOrNot(e.target.value, "uz")}} className='input' />
    </Form.Item>
    <Form.Item
      label="Rasm"
      name="image_str"
      rules={[
       
        {
           required: edit==null?true:req,
           message: "",
            
        }
      ]}
    >
      <input onChange={handleImageChange} type='file' className='input' />
    </Form.Item>
     <Form.Item
      wrapperCol={{
        span: 24,
      }}
className='btn_form_div'
    >
        <div className='btn_box'>
      <Button onClick={closeModal}>
        Yopish
      </Button>
      <Button disabled={error} type="primary"  htmlType="submit">
        Saqlash
      </Button>
      
      </div>
    </Form.Item>
  </Form>

    </Modal>
    {contextHolder}
    </div>
    </div>
    </>
  )
}

