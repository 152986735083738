import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import { getCarAdsOneHttp } from '../host/requests/CarAds'
import { useParams } from 'react-router-dom'
import { formatNumber } from '../components/Alert'
import { Col, Row } from 'antd'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'
import { FaFacebookF, FaGlobe, FaInstagram, FaPhone, FaTelegramPlane } from 'react-icons/fa'
import { formatFacebook, formatInstagram, formatTelegram, formatwebsite } from '../components/Checkers'

export default function CarAdsOne() {
    const {id}=useParams()
    const [data, setdata]=useState(null)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)
    useEffect(()=>{
        getData()
    },[])
    const getData=async()=>{
           setloader(true)
      
        try{
            var res=await getCarAdsOneHttp(id, token_access)
            setdata(res.data)
            setloader(false)
        }catch (error){
            console.log(error)
            setloader(false)
        }
    }
  return (
    data!=null?<>
    
     <div className='contentHeader'>
   
     </div>
    <div className='content'>
    <div className='contentBody'>
        <Row>
            <Col lg={12} md={24}>
            <div className='product_text'>
            <div className='product_text_head'>
                <div className='head_text_title'>
            <h1>{data.name}</h1>
            {data.cmtype!=null?<p>{data.cmtype[`name_uz`]}</p>:<></>}
            </div>
            </div>
            <div className='product_box'>
            {data.region!=null || data.district!=null?<div className='product_item'>
            <b>Manzil :</b><span> {data.region!=null?data.region[`name_uz`]:''}, {data.district!=null?data.district[`name_uz`]:''}</span>
            </div>:<></>}
            {data.b_type!=null?<div className='product_item'>
            <b>Bozor turi :</b><span> {data.b_type[`name_uz`]}</span>
            </div>:<></>}
            {data.year!=null?<div className='product_item'>
            <b>Ishlab chiqarilgan yili :</b><span> {data.year}</span>
            </div>:<></>}
           
            {data.ctype!=null?<div className='product_item'>
            <b>Avtomobil turi :</b><span> {data.ctype[`name_uz`]}</span>
            </div>:<></>}
            
            {data.model!=null?<div className='product_item'>
            <b>Model :</b><span> {data.model[`name_uz`]}</span>
            </div>:<></>}
            {data.position!=null?<div className='product_item'>
            <b>Pozitsiya :</b><span> {data.position[`name_uz`]}</span>
            </div>:<></>}
            {data.kuzov!=null?<div className='product_item'>
            <b>Kuzov :</b><span> {data.kuzov[`name_uz`]}</span>
            </div>:<></>}
            {data.color!=null?<div className='product_item'>
            <b>Mashina rangi :</b><span> {data.color[`name_uz`]}</span>
            </div>:<></>}
            {data.color_state!=null?<div className='product_item'>
            <b>Kuzov holati :</b><span> {data.color_state[`name_uz`]}</span>
            </div>:<></>}
            {data.transmission!=null?<div className='product_item'>
            <b>Uzatish qutisi :</b><span> {data.transmission[`name_uz`]}</span>
            </div>:<></>}
            {data.drive_unit!=null?<div className='product_item'>
            <b>Uzatma :</b><span> {data.drive_unit[`name_uz`]}</span>
            </div>:<></>}
           
            {data.engine_type!=null?<div className='product_item'>
            <b>Yonilg'i turi :</b><span> {data.engine_type[`name_uz`]}</span>
            </div>:<></>}
            {data.autosalon!=null?<div className='product_item'>
            <b>Avtosalonga tegishli :</b><span> {data.autosalon?"Bor":"Yo'q"}</span>
            </div>:<></>}
            {data.long_term_pay!=null?<div className='product_item'>
            <b>Bo'lib to'lashga beriladi :</b><span> {data.long_term_pay?"Bor":"Yo'q"}</span>
            </div>:<></>}
            {data.rent!=null?<div className='product_item'>
            <b>Ijaraga beriladi :</b><span> {data.rent?"Bor":"Yo'q"}</span>
            </div>:<></>}
            {data.probeg!=null?<div className='product_item'>
            <b>Probeg (km) :</b><span> {data.probeg}</span>
            </div>:<></>}
            {data.capacity!=null?<div className='product_item'>
            <b>Dvigatel hajmi (l) :</b><span> {data.capacity}</span>
            </div>:<></>}
           
            
            
          
            </div>
            <p className='product_price'>{formatNumber(data.price_usd)+" $"} | {formatNumber(data.price_uzs)+" so'm"}</p>
            {data.additional!==null && data.additional.length!==0?<div className='product_comment'>
               <p dangerouslySetInnerHTML={{__html:data.additional}}/> 
            </div>:''}
         </div>
         <div className='add_contact_box'>
         {data.created_by!==null?<div className='user_box'>
                <div className='avatar' style={{backgroundImage:`url(${data.created_by!==null && data.created_by.image!==null?data.created_by.image:null})`}}  />
                <div className='user_text'>
                  <h1>{data.created_by.first_name}</h1>
                  {data.phone!==null && data.phone.length!==0?<>{
                 <a href={`tel: ${data.phone}`}>{data.phone}</a>}
                  </>:<></>}
                </div>
                
            </div>:<></>}
            <div className='add_contact_links'>
            {data.phone!==null && data.phone.length!==0?<a href={`tel: ${data.phone}`} className='add_contact_icon' ><FaPhone /></a>:<></>}
            {data.telegram!==null && data.telegram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatTelegram(data.telegram)}><FaTelegramPlane /></a>:<></>}
            {data.instagram!==null && data.instagram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatInstagram(data.instagram)}><FaInstagram /></a>:<></>}
            {data.facebook!==null && data.facebook.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatFacebook(data.facebook)}><FaFacebookF /></a>:<></>}
            {data.website!==null && data.website.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatwebsite(data.website)}><FaGlobe /></a>:<></>}
            
            
            </div>
            </div>
         
         </Col>
            <Col lg={12} md={24}>
            <div className='images_ads'>
                {data.images.map((item, key)=>{
                    return(<div className='images_ads_box'><img src={item.image} /></div>)
                })}
                {data.video!=null?<div className='images_ads_box'>
                  <video style={{width:'100%'}} controls src={data.video} />
                </div>:<></>}
            </div>
            
        
 
            </Col>
        </Row>
   
    </div> </div>
    </>:<></>
  )
}
