import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getHouseServiceAdsHttp=async(data, token)=>{
    var res=await httpRequestGet('/services/house/', data, token)
    return(res)
}
export const getHouseServiceSelectionHttp=async(token)=>{
    var res=await httpRequestGet('/house-services-selections/', {}, token)
    return(res)
}
export const getHouseServiceAdsOneHttp=async(id, token)=>{
    var res=await httpRequestGet('/services/house/'+id+"/detail/", {}, token)
    return(res)
}
export const setHouseServiceAdsHttp=async(data, token)=>{
    var res=await httpRequestPost('/services/house/', data, token)
    return(res)
}
export const editHouseServiceAdsHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/services/house/${id}/update/`, data, token)
    return(res)
}
export const deleteHouseServiceAdsHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/services/house/${data}/delete/`, token)
    return(res)
}