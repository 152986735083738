import { message } from "antd"

export const successAlert=(text)=>{
    message.success(text, 3)
}
export const dangerAlert=(text)=>{
    message.error(text, 3)
}
export const formatNumber = (number) => {
    if(number!=null){
      return parseFloat(number).toLocaleString('en').replace(/,/g, ' ');
    }else{
      return "-"
    }
    
  };